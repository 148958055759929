import React, {useEffect} from 'react';
import {Routes, Route, useLocation} from 'react-router-dom'
import './Global';

import HomePage from './pages/HomePage';
import 'bootstrap/dist/css/bootstrap.min.css';
import PrivacyPage from "./pages/PrivacyPage";


function App() {


    return (
        <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/privacy" element={<PrivacyPage />} />
        </Routes>

);
}

export default App;