import React, { Component } from 'react'

// import '../style/homepage.css';


function HomePage(props) {
    
    return (
        <div>
            boazb.com 
        </div>
    )
   
}


export default HomePage;