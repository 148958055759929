import React, { Component } from 'react'


export default class PrivacyPage  extends Component{


    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
           


                <div className={'staticPage'}>
                    
                        <div style={{marginTop:50}}>
                            <div>
                                <div className={'pageHeading'}>Privacy Policy</div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div className={'pageSubheading'}>Last updated: 26 March 2024</div>
                            </div>
                        </div>

                        <div style={{marginTop:30, marginBottom:80}}>
                            <div>


                                <p>
                                    <h2>Terms</h2>
                                    This Privacy Policy informs you about our policies regarding the collection, use, and disclosure of information, including Personal Information (defined below), when you use our Service. We will not use or share your Personal Information with anyone except as described in this Privacy Policy. 
                                    By using the Service, you agree to the collection, use, and disclosure of information in accordance with this Privacy Policy. Unless otherwise defined in this Privacy Policy, capitalized terms used herein have the same meanings as in our Terms and Conditions.
                                </p>


                                <p>
                                    <h2>Information divlection</h2>
                                    While using our Service, we may ask you to provide us with certain information that can be used to contact or identify you. This information may include, but is not limited to, your name, email address, and profile photo ("Personal Information").
                                    When you send us an email message or otherwise contact us, we may receive Personal Information about you, including your name, email address, and the contents of messages or attachments that you may send to us, as well as other information you choose to provide, which may be associated with your communications.
                                    Should you choose to subscribe to our paid plans, you will provide payment information, including, but not limited to, payment card number, expiration date, security code, and billing address.
                                    We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Service that 
                                    you visit, the time and date of your visit, the time spent on those pages, and other statistics. When you access the Service by or through a mobile device, this Log Data may include information such as the type of mobile device you use, your mobile device's unique ID, the IP address of your mobile device, your mobile 
                                    operating system, the type of mobile Internet browser you use, and other statistics.
                                    In addition, we may use third-party services (including Amazon, Amplitude, Segment, Intercom, Google, Facebook, Twitter, Crashlytics, and Loggly, and their respective affiliates) that collect, monitor, and analyze this Log Data to provide analytics and other data to help us improve our Service's functionality and to 
                                    help us advertise our products and services. Please consult the section "Information Sharing with Third Parties" below for additional information regarding our service providers. These third-party service providers may use cookies, pixel tags, web beacons, or other storage technologies to collect and store Log Data 
                                    or information from elsewhere on the internet. They have their own privacy policies addressing how they use Log Data, and we do not have access to, nor control over, third parties' use of cookies or other tracking technologies.
                                </p>

                                <p>
                                    <h2>Cookies</h2>
                                    We use cookies to gather general information about your use of the Service, the files you have designated for backup, the configuration of your computer, and your computer's interaction with the Service, and store this information in log files. We use this information to understand traffic on and usage of our Service, enabling us to improve the Service, provide the best online experience possible, and improve our customer service. Our third-party services also use cookies as described above.                                
                                </p>

                                <p>
                                    <h2>Information Use</h2>
                                    We collect this information, including Personal Information, for the purpose of:
                                    <ul>
                                        <li>Providing the Service;</li>
                                        <li>Identifying and communicating with you, including newsletters and marketing materials, as described further below;</li>
                                        <li>Responding to your requests, including customer service inquiries;</li>
                                        <li>Processing your payments and otherwise servicing your purchase orders;</li>
                                        <li>Improving the content, products, services, and features of the Service, including to help us advertise our Service</li>
                                        <li>Creating anonymized and aggregated data sets that may be used for a variety of functions, including research, internal analysis, analytics, and other functions;</li>
                                        <li>Analyzing your usage of the Service; and</li>
                                        <li>Responding to valid legal process and valid requests from government authorities.</li>
                                    </ul>
                                </p>

                                <p>
                                    <h2>Communications</h2>
                                    We may contact you with newsletters and other marketing information that may be of interest to you. You may opt out of receiving any, or all, of these marketing communications from us by following the "unsubscribe" link or instructions provided in any email we send or by contacting us. Please note that we may still send you transactional or administrative messages related to the Service even after you have opted out of receiving marketing communications.
                                </p>

                                <p>
                                    <h2>Information Sharing with Third Parties</h2>
                                    We may share your Personal Information with third parties outside the following limited circumstances to which you consent:
                                    <ul>
                                        <li>
                                        Service Providers: We work with third-party service providers to perform services on our behalf related to our Service. These third-party service providers include, but are not limited to, Stripe, Intercom, Amazon, Facebook, Twitter, Google, Amplitude, Crashlytics, Loggly, and Segment, and their respective affiliates. We use these third-party service providers in connection with the Service's hosting infrastructure, payment processing, login functionality, analytics related to the Service, marketing campaigns as described below, and customer service efforts. We may share your Personal Information with such service providers in connection with the procurement of such services. Please consult the section "Links to Other Sites" below and the third party's privacy policy for additional information.

                                        </li>
                                        <li>
                                        Marketing our Service: We may use and share your Personal Information with third-party service providers to market our own Service and grow our Service's user base, such as to provide targeted marketing about our own Service via third-party platforms.

                                        </li>
                                        <li>
                                        Accessible by Other Users: When you submit Personal Information, such Personal Information may be displayed to other users of the platform. For example, your Personal Information may include your username and avatar photo.

                                        </li>
                                        <li>
                                        Compliance with Law: We will disclose your Personal Information where required to do so by law or subpoena or if we believe that such action is necessary to comply with the law and the reasonable requests of law enforcement or to protect the security or integrity of our Service.

                                        </li>
                                        <li>
                                        Sale or Merger: If we directly or indirectly undergo a business transition (including a proposed transaction), like a merger, acquisition by another company, or sale of all or part of our assets, we may disclose or transfer information collected from, and about, you (including your Personal Information), to the successor organization in (or in anticipation of) the transition.

                                        </li>
                                    </ul>
                                </p>


                                <p>
                                    <h2>Security</h2>
                                    The security of your Personal Information is important to us. However, please be aware that no method of transmission over the internet, or method of electronic storage, is 100% secure, and we are unable to guarantee the absolute security of the Personal Information we have collected from you.


                                </p>


                                <p>
                                    <h2>International Transfer</h2>
                                    Information collected while you use the Service, including your Personal Information, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction. Your consent to this Privacy Policy followed by your submission of such Personal Information represents your agreement to that transfer.
                                </p>

                                <p>
                                    <h2>Questions or Concerns Regarding the Privacy Policy</h2>
                                    If you have questions or concerns regarding privacy using our Service, please contact us at: 
                                    &nbsp;<a href="mailto:legal@boazb.com">legal@boazb.com</a>
                                </p>


                            </div>
                        </div>



                   
                </div>

        )
    }
}